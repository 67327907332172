import Navbar from "../components/Navbar";
import PlaylistList from "../components/Playlists";
import { TaskPage } from "../tasks/page";

function Home() {
  return (
    <>
      {/* <Navbar />
      <HomeCards />
      <div className="container mx-auto px-4 py-8">
        <PlaylistList />
      </div> */}
      <TaskPage />
    </>
  );
}
export default Home;
