import React from 'react';

const Login: React.FC = () => {
  const handleSpotifyAuth = () => {
    window.location.href = `${process.env.REACT_APP_BE_URL}/spotify/login/`;
  };

  const handleSoundcloudAuth = () => {
    window.location.href = `${process.env.REACT_APP_BE_URL}/soundcloud/login/`;
  };

  return (
    <div>
      <button onClick={handleSpotifyAuth}>
        Authorize Spotify
      </button>
      <button onClick={handleSoundcloudAuth}>
        Authorize Soundcloud
      </button>
    </div>
  );
};

export default Login;