"use client";

import { Checkbox } from "src/shadcn/components/ui/checkbox";

import { priorities, statuses } from "../data/data";
import { DataTableColumnHeader } from "./data-table-column-header";
import { DataTableRowActions } from "./data-table-row-actions";

export const columns = [
  {
    id: "select",
    header: ({ table }) => (
      <Checkbox
        checked={
          table.getIsAllPageRowsSelected() ||
          (table.getIsSomePageRowsSelected() && "indeterminate")
        }
        onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
        aria-label="Select all"
        className="translate-y_[2px]"
      />
    ),
    cell: ({ row }) => (
      <Checkbox
        checked={row.getIsSelected()}
        onCheckedChange={(value) => row.toggleSelected(!!value)}
        aria-label="Select row"
        className="translate-y_[2px]"
      />
    ),
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: "id",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Playlist Id" />
    ),
    cell: ({ row, column }) => (
      <div className="w-[140px]">{row.getValue("id")}</div>
    ),
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: "name",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Name" />
    ),
    cell: ({ row }) => {
      const smallestImage = row.original.images.sort(
        (a, b) => a.width - b.width
      )[0];
      const owner = row.getValue("owner");

      return (
        <div className="flex items-center space-x-2">
          <img
            src={smallestImage.url}
            alt="playlist"
            className="w-10 h-10 rounded"
          />
          <div className="flex flex-col justify-center">
            {" "}
            <span className="max-w-[500px] truncate font-medium">
              {row.getValue("name")}
            </span>
            <div className="text-sm text-gray-500">{owner.display_name}</div>
          </div>
        </div>
      );
    },
  },
  {
    accessorKey: "owner",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Owner" />
    ),
    cell: ({ row }) => {
      const status = statuses[2]; // TODO:
      const owner = row.getValue("owner");

      return (
        <div className="flex w-[100px] items-center">
          {status.icon && (
            <status.icon className="mr-2 h-4 w-4 text-muted-foreground" />
          )}
          <span>{owner.display_name}</span>
        </div>
      );
    },
  },
  {
    accessorKey: "tracks",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Tracks" />
    ),
    cell: ({ row }) => {
      const priority = priorities[0];

      if (!priority) {
        return null;
      }

      return <div className="">{row.getValue("tracks")}</div>;
    },
  },
  {
    id: "actions",
    cell: ({ row }) => <DataTableRowActions row={row} />,
  },
];
