import { useEffect, useState } from "react";
import client from "../api";
import { columns } from "./components/columns";
import { DataTable } from "./components/data-table";
import { UserNav } from "./components/user-nav";

// TODO: remove this once the data is being fetched from the API

export function TaskPage() {
  const [playlists, setPlaylists] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // TODO: move fetchPlaylists to api.js
    const fetchPlaylists = async () => {
      try {
        const response = await client.get("/spotify/playlists/");
        setPlaylists(response.data);
      } catch (error) {
        console.error("There was an error fetching the playlists:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchPlaylists();
  }, []);

  return (
    <>
      {/* TODO: remove pb-64 */}
      <div className="hidden h-full flex-1 flex-col space-y-8 p-8 md:flex pb-64">
        <div className="flex items-center justify-between space-y-2">
          <div>
            <h2 className="text-2xl font-bold tracking-tight">Welcome back!</h2>
            <p className="text-muted-foreground">
              Here&apos;s a list of your playlists!
            </p>
          </div>
          <div className="flex items-center space-x-2">
            <UserNav />
          </div>
        </div>
        <DataTable data={playlists} columns={columns} />
      </div>
    </>
  );
}
