import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "./pages/Login";
import PlaylistList from "./components/Playlists";
import Home from "./pages/Home";
import ProtectedRoute from "./components/ProtectedRoute";
import "./App.css";

// TODO:
// function Logout() {
//   return (
//     <div>
//       <h1>Logout</h1>
//     </div>
//   );
// }

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<ProtectedRoute element={<Home />} />} />
        <Route path="/TEST" element={<Home />} />
      </Routes>
    </Router>
  );
};

export default App;
