import axios from "axios";

const getCsrfToken = () => {
  return document.cookie
    .split("; ")
    .find((row) => row.startsWith("csrftoken="))
    ?.split("=")[1];
};

const client = axios.create({
  baseURL: process.env.REACT_APP_BE_URL,
  withCredentials: true,
  headers: {
    "X-CSRFToken": getCsrfToken(), // MAKE SURE to never include in call to external api
  },
});

export default client;

export const createReleaseList = async (
  playlistId,
  newPlaylistName,
  startDate,
  endDate
) => {
  try {
    const response = await client.post("/spotify/release-list/", {
      playlist_id: playlistId,
      new_playlist_name: newPlaylistName,
      start_date: startDate.toISOString(),
      end_date: endDate.toISOString(),
    });
    return response.data;
  } catch (error) {
    console.error("There was an error fetching the release list:", error);
  }
};
