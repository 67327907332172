"use client";

import { zodResolver } from "@hookform/resolvers/zod";
import { addDays } from "date-fns";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { createReleaseList } from "src/api";
import { Button } from "src/shadcn/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "src/shadcn/components/ui/dialog";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "src/shadcn/components/ui/form";
import { Input } from "src/shadcn/components/ui/input";
import { z } from "zod";
import { CalendarDateRangePicker } from "./date-range-picker";

const formSchema = z.object({
  dateRange: z.object(
    {
      from: z.date(),
      to: z.date(),
    },
    "Date range"
  ),
  newPlaylistName: z.string().min(1, {
    message: "Please provide a playlist name.",
  }),
});

export function ProfileForm({ table }) {
  const rows = table.getSelectedRowModel().rows;
  const selectedPlaylist = rows.map((row) => row.original)[0];

  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      dateRange: {
        from: addDays(new Date(), -7),
        to: new Date(),
      },
      newPlaylistName: "",
    },
  });

  const { dateRange } = form.watch();

  useEffect(() => {
    if (!selectedPlaylist) {
      return;
    }

    let newPlaylistName = `${selectedPlaylist.name}: Releases`;

    if (dateRange?.from && dateRange?.to) {
      const startDate = dateRange.from;
      const endDate = dateRange.to;

      const dateRangeStr = `${
        startDate.getMonth() + 1
      }/${startDate.getDate()}-${endDate.getMonth() + 1}/${endDate.getDate()}`;

      newPlaylistName = `${selectedPlaylist.name}: ${dateRangeStr} Releases`;
    }

    form.setValue("newPlaylistName", newPlaylistName);
  }, [dateRange, selectedPlaylist]);

  // 2. Define a submit handler.
  async function onSubmit(values) {
    // default to past 7 days
    const startDate = values.dateRange.from;
    const endDate = values.dateRange.to;
    const newPlaylistName = values.newPlaylistName;

    const releaseList = await createReleaseList(
      selectedPlaylist.id,
      newPlaylistName,
      startDate,
      endDate
    );
    console.log(releaseList);
  }

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button size="lg" disabled={!selectedPlaylist}>
          Create Release List
        </Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[475px]">
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
            <DialogHeader>
              <DialogTitle>Create Release List</DialogTitle>
              <DialogDescription>
                Creates a new playlist with releases for the specified date
                range.
              </DialogDescription>
            </DialogHeader>
            <FormField
              control={form.control}
              name="dateRange"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Date range</FormLabel>
                  <CalendarDateRangePicker
                    startDate={field.value?.from}
                    endDate={field.value?.to}
                    onDatesChange={(range) => field.onChange(range)}
                  />
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="newPlaylistName"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Playlist Name</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <DialogFooter className="sm:justify-center">
              <Button type="submit" size="lg">
                Create
              </Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
}
